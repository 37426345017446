var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "DashboardClientTransfers column",
        {
          "DashboardClientTransfers--mobile": _vm.mobile.isMobile
        }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "DashboardClientTransfers__table column mb-22" },
        [
          _vm.mobile.isMobile
            ? _c(
                "label",
                {
                  staticClass:
                    "DashboardClientTransfers__mobile-heading fc-light fs-14 mt-15 mb-15 row"
                },
                [
                  _vm._v(
                    "\n      Funds that have been transferred to you\n    "
                  )
                ]
              )
            : _vm._e(),
          _c(
            "transition",
            { attrs: { name: "Transition__fade" } },
            [
              _vm.transfers
                ? _c(
                    "base-client-transfer-table",
                    { attrs: { transfers: _vm.transfers } },
                    [
                      !_vm.mobile.isMobile
                        ? _c(
                            "div",
                            { staticClass: "Table__slot-content column" },
                            [
                              _c("label", { staticClass: "fs-16 fw-med" }, [
                                _vm._v("Transfers")
                              ]),
                              _c("label", { staticClass: "fc-light fs-14" }, [
                                _vm._v(
                                  "Funds that have been transferred to you"
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("base-pagination", {
        staticClass: "mb-22",
        attrs: {
          "item-count": _vm.transfersCount,
          page: _vm.transfersPage,
          "request-limit": 5
        },
        on: { paginate: _vm.getTransfers }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }