<template>
  <div
    :class="['DashboardClientTransfers column', {
      'DashboardClientTransfers--mobile': mobile.isMobile
    }]"
  >
    <!-- CLIENT TRANSFERS -->
    <div class="DashboardClientTransfers__table column mb-22">
      <label
        v-if="mobile.isMobile"
        class="DashboardClientTransfers__mobile-heading fc-light fs-14 mt-15 mb-15 row"
      >
        Funds that have been transferred to you
      </label>

      <transition name="Transition__fade">
        <base-client-transfer-table
          v-if="transfers"
          :transfers="transfers"
        >
          <div
            v-if="!mobile.isMobile"
            class="Table__slot-content column"
          >
            <label class="fs-16 fw-med">Transfers</label>
            <label class="fc-light fs-14">Funds that have been transferred to you</label>
          </div>
        </base-client-transfer-table>
      </transition>
    </div>

    <base-pagination
      @paginate="getTransfers"
      class="mb-22"
      :item-count="transfersCount"
      :page="transfersPage"
      :request-limit="5"
    />
  </div>
</template>

<script>
// Helpers
import { ClientPayment } from '../../utils/api'
// Components
import BasePagination from '../../components/base-pagination.vue'
import BaseClientTransferTable from '../../components/base-client-transfer-table.vue'

export default {
  name: 'DashboardClientTransfers',

  components: {
    BasePagination,
    BaseClientTransferTable,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },

  async created () {
    await this.getTransfers()
  },

  data () {
    return {
      transfers: null,
      transfersCount: null,
      transfersPage: 1,
    }
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },
  },

  methods: {
    async getTransfers (page = null) {
      this.progressStart()

      if (page) this.transfersPage = page

      try {
        const transfers = (await ClientPayment.queryList({
          client_id: this.id,
          include: ['bankpayments', 'balance_invoices'],
          limit: 5,
          offset: page ? (page - 1) * 5 : 0,
          sort_by: 'created_at',
          sort_direction: 'DESC',
        })).data
        this.transfers = transfers.rows.map(transfer => {
          Object.assign(transfer, { expanded: false })
          // make the sign correct for UI
          transfer.invoices.forEach(invoice => {
            if (invoice.approved_invoice_adjustment_amount) {
              invoice.approved_invoice_adjustment_amount *= -1
            }
          })
          // sum the approved_invoice_adjustment_amount
          transfer.approved_invoice_adjustment_amount = transfer.invoices
            .reduce((acc, curr) => (acc + curr.approved_invoice_adjustment_amount), 0)
          return transfer
        })
        this.transfersCount = transfers.count
        this.progressFinish()
      } catch (error) {
        this.captureSentryEvent(
          'Dashboard Client Transfers "Get Transfers"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue getting the client\'s transfers' })
      }
    },
  },

}
</script>

<style lang="sass">
.DashboardClientTransfers
  $block: &

  &__mobile-heading
    display: none
    padding: 0 1rem

  &--mobile
    background-color: $background
    min-height: calc(100vh - 60px)
    padding: 0 !important

    #{$block}__mobile-heading
      display: flex
</style>